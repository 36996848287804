<script lang="ts" setup>
const props = defineProps<{
  title: String;
}>();

const open = ref(false);

const updateModal = () => {
  open.value = !open.value;
};
</script>

<template>
  <div class="first:border-t-1 border-b-1 border-maas-border-grey-normal w-full px-2  font-[inherit]">
    <div class="relative flex cursor-pointer py-6 select-none font-[inherit]" @click="updateModal()">
      <slot name="title">
        <p class="grow font-semibold font-[inherit]">{{ props.title }}</p>
      </slot>
      <span class="shrink-0 grow-0 text-2xl">
        <svg fill="none" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path class="stroke-2" d="M5 12h14" stroke="currentColor"></path>
          <path class="stroke-2" v-if="!open" d="M12 5v14" stroke="currentColor"></path>
        </svg>
      </span>
    </div>
    <div v-if="open" class="pb-6">
      <slot />
    </div>
  </div>
</template>
